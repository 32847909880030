import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingButton from 'components/common/LoadingButton';
import classNames from 'classnames';
import authServices from 'services/auth.services';

export default function StripeConnectStatus({
  stripeConnected,
  id,
  canModify,
  type,
  onSuccess,
  countryCode = 'AU'
}) {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const defaultStripeConnectParams = {
    country: countryCode,
    business_type: 'individual',
    platform: 'website'
  };

  const handleConnectStripe = async () => {
    try {
      setLoadingSubmit(true);
      await authServices.verifyStripeConnect({
        id,
        type,
        ...(stripeConnected ? { is_update: 'true' } : { is_update: 'false' }),
        ...defaultStripeConnectParams,
        country_code: countryCode
      });
      onSuccess();
      toast.success('The email was successfully sent.', {
        theme: 'colored'
      });
    } catch (error) {
      toast.error('The email was sent fail.', {
        theme: 'colored'
      });
      console.error(error.message);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const getBtnName = () => {
    if (canModify) {
      if (stripeConnected) return 'Change';
      return 'Connect';
    }
    if (!stripeConnected) return 'Not connect';
    return 'Connect';
  };

  if (!id) {
    return (
      <Badge bg="danger" style={{ minWidth: 65 }} className="justify-content-center d-flex">
        Not connect
        <FontAwesomeIcon icon="check" className="ms-1" transform="shrink-2" />
      </Badge>
    );
  }
  return (
    <LoadingButton
      isLoading={loadingSubmit}
      className={classNames('fs--2 justify-content-center d-flex', {
        'btn-warning': !stripeConnected,
        'btn-success': stripeConnected
      })}
      btnProps={{
        onClick: handleConnectStripe,
        size: 'sm',
        disabled: !canModify,
        style: { minWidth: 80 }
      }}
      name={getBtnName()}
    />
  );
}
