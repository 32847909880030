import { axiosClient, axiosPublicClient } from './request';

const authServices = {
  login(body) {
    return axiosPublicClient.post('/v1/auth/signin', {
      username: body.email,
      password: body.password
    });
  },
  socialLogin(accessToken) {
    return axiosPublicClient.post('/auth/authenticate', null, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },
  verifyUser(accessToken) {
    return axiosPublicClient.post('/auth/verify', null, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },
  verifyStripeConnect(body) {
    return axiosPublicClient.post('/stripe-express/connect', body, {
      headers: {
        'x-region': body?.country_code || ''
      }
    });
  },
  verifyStripeConnectOnboard(body) {
    return axiosPublicClient.post('/stripe-express/return', body, {
      headers: {
        'x-region': body?.country_code || ''
      }
    });
  },
  resendActivationEmail(token) {
    return axiosClient.post('auth/resend', null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
};

export default authServices;
