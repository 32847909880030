import React from 'react';
import { Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';

export default function StripeConnectSuccess() {
  return (
    <Flex className="w-100 min-vh-100 bg-white" justifyContent="center" alignItems="center">
      <Spinner animation="border" />
    </Flex>
  );
}
