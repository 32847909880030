import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { WizardInpuHorizontal } from 'components/wizard';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useGetCountry from 'hooks/customHooks/useGetCountry';
import { useAuth } from 'hooks/useAuth';
import { genCompanyFormSchema } from '../../config';
import { commonConfig, formLayout } from 'helpers/formLayoutConfig';
import useFetchGroup from 'pages/Group/services/useFetchGroup';
import { getListOptions, getValueObj, isValidEmail as isValidEmailInput } from 'helpers/utils';
import companyServices from 'services/company.services';
import StripeConnectStatus from 'components/pages/StripeConnectStatus';
import useGetTimezones from 'hooks/customHooks/useGetTimezones';
import PrincipalDetailForm from 'components/pages/PrincipalDetailForm';
import groupServices from 'services/group.services';
import FooterActionBtn from 'components/pages/FooterActionBtn';
import useCheckDuplicateEmail from 'hooks/customHooks/useCheckDuplicateEmail';
import useCheckValidABN from 'hooks/customHooks/useCheckValidABN';
import { DUPLICATE_EMAIL, ROLES, YupMessage, COMPANY, PAYMENT_POINT } from 'constants/index';
import { COMPANY_PRINCIPAL } from 'components/pages/PrincipalDetailForm/useAuthorizePrincipal';
import { useCompanyContext } from 'pages/Company/useCompanyContext';
import LoadingButton from 'components/common/LoadingButton';

export default function GeneralInfo() {
  const {
    data,
    companyId,
    onCloseModal,
    loadingDetail,
    fetchDetailCompany,
    onCreateOrEditCompanySuccess
  } = useCompanyContext();
  const [loading, setLoading] = useState(false);
  const [assigningStripeAccId, setAssigningStripeAccId] = useState(false);
  const { viewAsRole, userData } = useAuth();
  const { listGroup, fetchListGroup } = useFetchGroup();
  const [isAssignExistUser, setIsAssignExistPrincipal] = useState(false);
  const [disabledForm, setDisabledForm] = useState(true);
  const viewDetailMode = Boolean(companyId); // view detail item
  const viewMode = viewDetailMode && disabledForm; // only view item
  const editMode = viewDetailMode && !disabledForm; // enable update item

  const formMethods = useForm({
    resolver: yupResolver(genCompanyFormSchema(isAssignExistUser)),
    values: data,
    mode: 'all'
  });

  const {
    register,
    watch,
    control,
    reset,
    setError,
    clearErrors,
    setValue,
    trigger,
    handleSubmit,
    formState
  } = formMethods;
  const { errors, isSubmitted } = formState;
  const countryCode = watch('address.country_id');
  const groupId = watch('group_id');
  const groupRate = watch('group_comm');
  const companyRate = watch('company_comm');
  const principalCountryCode = watch('user_principal.address.country_id');
  const emailInput = watch('user_principal.email');
  const existEmailInput = watch('existing_principal');
  const abnPrincipalInput = watch('user_principal.tax_number');
  const abnInput = watch('tax_number');
  const byPassCheckAbn = [ROLES.COMPANY, ROLES.GROUP].includes(viewAsRole);

  const { pendingCheckAbn } = useCheckValidABN({
    clearErrors,
    setError,
    formState,
    abn: abnInput,
    name: 'tax_number',
    bypass: byPassCheckAbn,
    validateSpecificField: true
  });

  const { pendingCheckAbn: pendingCheckPrincipalAbn } = useCheckValidABN({
    setError,
    formState,
    clearErrors,
    abn: abnPrincipalInput,
    bypass: byPassCheckAbn,
    validateSpecificField: true,
    name: 'user_principal.tax_number'
  });

  const { errMsg, isValidEmail, pendingCheckingEmail, resetState } = useCheckDuplicateEmail({
    email: emailInput,
    bypass: viewMode || (editMode && emailInput == data.user_principal?.email) || !emailInput
  });

  const { fetchCountries, listCountry, listState } = useGetCountry({ countryId: countryCode });
  const { listState: listPrincipalState } = useGetCountry({ countryId: principalCountryCode });
  const { listTimezones } = useGetTimezones();

  const onChangePrincipalCountry = e => {
    const principalCountryId = Number(e.target.value) || undefined;
    setValue('user_principal.address.country_id', principalCountryId);
    setValue('user_principal.address.state_id', undefined);
  };

  const onChangeTypePrincipal = e => {
    setIsAssignExistPrincipal(e.target.checked);
    if (!e.target.checked) {
      setValue('existing_principal', undefined);
    }
  };

  // Sync error message between email form validation and api email validation
  const onValidatePrincipalEmail = () => {
    if (!emailInput) {
      setError('user_principal.email', {
        type: 'optionality',
        message: YupMessage.REQUIRED
      });
      resetState();
      return;
    }

    const errorMsgEmail = getValueObj(errors, 'user_principal.email'.split('.'));

    if (isValidEmailInput(emailInput) && isValidEmail && errMsg && errorMsgEmail?.type === 'custom')
      clearErrors('user_principal.email');

    // By pass if user revert principal email to current principal email
    if (errMsg && !isValidEmail && !(editMode && emailInput == data.user_principal?.email)) {
      setError('user_principal.email', {
        type: 'custom',
        message: errMsg
      });
    }

    if (!isValidEmailInput(emailInput)) {
      setError('user_principal.email', {
        type: 'custom',
        message: YupMessage.INVALID_EMAIL
      });
    }
  };

  const onChangeGroup = e => {
    const groupId = Number(e.target.value);
    setValue('group_id', Number(groupId) || undefined);

    // prefill country base on group
    const selectedGroup = listGroup?.items?.find(item => item.id === groupId);
    if (selectedGroup?.country_id) {
      setValue('address.country_id', selectedGroup?.country_id);
      trigger('address.country_id');
      setValue('user_principal.address.country_id', selectedGroup?.country_id);
      trigger('user_principal.address.country_id');
      setValue('user_principal.address.state_id', undefined);
    }
  };

  const fetchAvailablePrincipal = value => {
    if (!groupId) return;
    return groupServices.getAvailablePrincipal(groupId, {
      user_name: value
    });
  };

  const onReset = () => {
    reset(data);
    setDisabledForm(true);
    setIsAssignExistPrincipal(false);
    setAssigningStripeAccId(false);
  };

  const onEnableEditForm = () => {
    setDisabledForm(false);
  };

  const onSubmit = async dataSubmit => {
    try {
      setLoading(true);
      let { existing_principal, ...databody } = dataSubmit;
      databody.assign_flag = false;

      if (existing_principal?.email) {
        databody = {
          ...databody,
          assign_flag: true,
          user_principal: {
            email: existing_principal.email,
            id: existing_principal.id
          }
        };
      }
      if (companyId) {
        if (databody?.user_principal?.email !== data.user_principal?.email && !isAssignExistUser) {
          databody.user_principal.id = 0;
        }
        await companyServices.updateCompany(companyId, databody);
        onCreateOrEditCompanySuccess();
        toast.success('The company was successfully updated.');
        return;
      }
      await companyServices.create(databody);
      onCreateOrEditCompanySuccess();
      toast.success('The company was successfully created.');
    } catch (err) {
      if (err?.error?.type === DUPLICATE_EMAIL) {
        setError('user_principal.email', { type: 'optionality', message: err?.error?.message });
      }
      toast.error(err?.error?.message || 'Action has been failed.');
    } finally {
      setLoading(false);
    }
  };

  const onSuccess = () => {
    fetchDetailCompany(data.id);
  };

  useEffect(() => {
    if (viewDetailMode) return;
    if (!emailInput) return;
    onValidatePrincipalEmail();
  }, [emailInput, isValidEmail, errMsg, viewDetailMode]);

  useEffect(() => {
    if (groupRate && isSubmitted) {
      trigger('company_comm');
    }
  }, [groupRate, companyRate, isSubmitted]);

  useEffect(() => {
    if (groupId && listGroup?.items?.length) {
      const selectedGroup = (listGroup.items || []).find(item => item.id === groupId);
      setValue('group_comm', selectedGroup?.group_comm);
    }
  }, [groupId, listGroup]);

  useEffect(() => {
    setValue('existing_principal', null);
  }, [groupId]);

  useEffect(() => {
    fetchCountries();
    fetchListGroup({ pageSize: 1000 });
  }, []);

  const isPending =
    loadingDetail || pendingCheckAbn || pendingCheckPrincipalAbn || pendingCheckingEmail;
  const hasFormError = Object.keys(errors).filter(item => item !== 'existing_principal').length > 0;

  const disabledCreate = isAssignExistUser
    ? !existEmailInput
    : isPending || hasFormError || !isValidEmailInput(emailInput);

  return (
    <FormProvider {...formMethods}>
      <form id="add-update-company" onSubmit={handleSubmit(onSubmit)}>
        <Card className="mb-3">
          <Card.Header className="d-flex justify-center align-center bg-primary-subtle">
            <h5 className="mb-0 fs-0 mr-auto">Details</h5>
          </Card.Header>
          <Card.Body className="border-top fs--1 position-relative">
            {loadingDetail && <LoadingOverlay />}
            <Row>
              <Col xs={12} lg={6}>
                <div className="pb-3 pt-1">
                  <span className="fs--1 fw-bolder">Company Information</span>
                </div>
                <WizardInpuHorizontal
                  label="Company Name"
                  required
                  name="name"
                  errors={errors}
                  placeholder="Company Name"
                  {...commonConfig({
                    register: register('name'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.COMPANY],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  type="select"
                  required
                  watch={watch}
                  options={getListOptions(listCountry)}
                  defaultOption={{
                    label: data?.address?.country_name,
                    value: data?.address?.country_id
                  }}
                  label="Country"
                  name="address.country_id"
                  errors={errors}
                  placeholder="Add country"
                  {...commonConfig({
                    register: register('address.country_id', { valueAsNumber: true }),
                    disabled: true,
                    permissions: {
                      VIEW: [ROLES.COMPANY],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  type="select"
                  options={getListOptions(listState)}
                  label="State"
                  watch={watch}
                  name="address.state_id"
                  errors={errors}
                  placeholder="Add state"
                  {...commonConfig({
                    register: register('address.state_id', { valueAsNumber: true }),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.COMPANY],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Address"
                  required
                  name="address.detail_address"
                  placeholder="Address"
                  errors={errors}
                  {...commonConfig({
                    register: register('address.detail_address'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.COMPANY],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  required
                  label="Locality"
                  name="address.city"
                  errors={errors}
                  placeholder="Locality"
                  {...commonConfig({
                    register: register('address.city'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.COMPANY],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Post Code/ Zipcode"
                  name="address.postcode"
                  required
                  errors={errors}
                  placeholder="Post Code/ Zipcode"
                  {...commonConfig({
                    register: register('address.postcode'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.COMPANY],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Time Zones"
                  name="timezone_id"
                  required
                  errors={errors}
                  placeholder="Select Time Zones"
                  type="select"
                  options={getListOptions(listTimezones, 'id', 'text')}
                  defaultOption={{
                    label: data?.timezone_text,
                    value: data?.timezone_id
                  }}
                  {...commonConfig({
                    register: register('timezone_id', { valueAsNumber: true }),
                    disabled: viewMode,
                    formControlProps: {
                      className: 'fs--1 text-truncate'
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Phone number"
                  name="contact_number"
                  errors={errors}
                  placeholder="Phone number"
                  type="phoneInput"
                  control={control}
                  defaultCountry={userData?.address?.countryCode}
                  {...commonConfig({
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.COMPANY],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Email"
                  name="email"
                  errors={errors}
                  placeholder="Email"
                  {...commonConfig({
                    register: register('email'),
                    disabled: viewMode,
                    permissions: {
                      VIEW: [ROLES.COMPANY],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
                    }
                  })}
                />
              </Col>
              <Col xs={12} lg={6}>
                <div className="pb-3 pt-1">
                  <span className="fs--1 fw-bolder">Operations</span>
                </div>
                <WizardInpuHorizontal
                  label="Tax ID"
                  required
                  type="number"
                  name="tax_number"
                  errors={errors}
                  placeholder="Tax ID"
                  {...commonConfig({
                    register: register('tax_number'),
                    disabled: viewMode,
                    formGroupProps: {
                      className: 'mb-4'
                    },
                    permissions: {
                      VIEW: [ROLES.COMPANY],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Group"
                  required
                  name="group_id"
                  type="select"
                  watch={watch}
                  errors={errors}
                  placeholder="Group"
                  options={getListOptions(listGroup.items || [])}
                  {...commonConfig({
                    formControlProps: {
                      onChange: onChangeGroup
                    },
                    register: register('group_id', { valueAsNumber: true }),
                    disabled: viewDetailMode,
                    formGroupProps: {
                      className: 'mb-1 mt-3'
                    },
                    permissions: {
                      VIEW: [ROLES.COMPANY],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
                    }
                  })}
                />
                <WizardInpuHorizontal
                  label="Group Rate"
                  name="group_comm"
                  errors={errors}
                  placeholder="Group"
                  {...commonConfig({
                    register: register('group_comm'),
                    disabled: true,
                    formGroupProps: {
                      className: 'mb-1'
                    }
                  })}
                />
                <WizardInpuHorizontal
                  required
                  label="Receipt Contact Details"
                  name="receipt_contact_info"
                  errors={errors}
                  placeholder="Receipt Contact Details"
                  {...commonConfig({
                    register: register('receipt_contact_info'),
                    disabled: viewMode,
                    formGroupProps: {
                      className: 'mb-4'
                    },
                    permissions: {
                      VIEW: [],
                      MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP, ROLES.COMPANY]
                    }
                  })}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row className="g-2">
          <Col xs={12} lg={6} className="d-flex">
            <Card className="mb-3 flex-1">
              <Card.Header className="d-flex justify-center align-center bg-primary-subtle">
                <h5 className="mb-0 fs-0 mr-auto">Payout Details</h5>
              </Card.Header>
              <Card.Body className="border-top fs--1 position-relative">
                {loadingDetail && <LoadingOverlay />}
                <div className="w-100">
                  <WizardInpuHorizontal
                    label="Payment Point"
                    required
                    name="payment_point"
                    type="select"
                    watch={watch}
                    options={PAYMENT_POINT}
                    errors={errors}
                    placeholder="Select Payment Point"
                    {...commonConfig({
                      register: register('payment_point'),
                      disabled: viewMode,
                      formControlProps: {
                        style: {
                          paddingRight: '3rem'
                        }
                      },
                      permissions: {
                        VIEW: [ROLES.COMPANY],
                        MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
                      }
                    })}
                  />

                  <WizardInpuHorizontal
                    type="number"
                    label="Endpoint Rate"
                    name="company_comm"
                    required
                    errors={errors}
                    placeholder="Company Rate"
                    {...commonConfig({
                      register: register('company_comm', {
                        valueAsNumber: true
                      }),
                      disabled: viewMode,
                      permissions: {
                        VIEW: [ROLES.COMPANY],
                        MODIFY: [ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP]
                      }
                    })}
                  />
                  <Row className="mb-2">
                    <Col {...formLayout.label}>
                      <p className="label col-form-label mb-0">Stripe connected</p>
                    </Col>
                    <Col {...formLayout.col} className="d-flex gap-3 align-items-center">
                      <StripeConnectStatus
                        id={data.id}
                        type="company"
                        stripeConnected={data.stripe_connect_enabled === 1}
                        canModify={[ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP].includes(
                          viewAsRole
                        )}
                        onSuccess={onSuccess}
                        countryCode={data.group_country_code}
                      />
                      {viewAsRole === ROLES.ADMIN && editMode && (
                        <LoadingButton
                          name="Assign Existing"
                          className="btn-info fs--2 justify-content-center d-flex"
                          btnProps={{
                            onClick: () => setAssigningStripeAccId(true),
                            size: 'sm'
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  {assigningStripeAccId ? (
                    <WizardInpuHorizontal
                      label="Stripe Account ID"
                      name="stripe_account_id"
                      errors={errors}
                      placeholder="Stripe Account ID"
                      {...commonConfig({
                        disabled: false,
                        register: register('stripe_account_id')
                      })}
                    />
                  ) : (
                    <Row className="mb-1">
                      <Col {...formLayout.label}>
                        <span className="label">Stripe Account ID</span>
                      </Col>
                      <Col {...formLayout.col}>{data.stripe_account_id}</Col>
                    </Row>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={6} className="d-flex">
            <PrincipalDetailForm
              data={data}
              loading={loadingDetail}
              viewMode={viewMode}
              pageKey={COMPANY}
              onChangeCountry={onChangePrincipalCountry}
              typePrincipal={COMPANY_PRINCIPAL}
              isDisabled={!groupId}
              listCountry={listCountry}
              listState={listPrincipalState}
              viewDetailMode={viewDetailMode}
              isAssignExistUser={isAssignExistUser}
              onChangeTypePrincipal={onChangeTypePrincipal}
              onBlurEmailInput={onValidatePrincipalEmail}
              fetchAvailablePrincipal={fetchAvailablePrincipal}
            />
          </Col>
        </Row>
      </form>

      <div className="d-flex ml-auto px-4 py-2 position-absolute w-100 bottom-0 start-0 justify-content-end bg-body-quaternary border-top">
        <FooterActionBtn
          id={companyId}
          onClose={onCloseModal}
          loading={loading}
          onReset={onReset}
          viewMode={viewMode}
          disabled={disabledCreate}
          viewDetailMode={viewDetailMode}
          onEdit={onEnableEditForm}
          formName="add-update-company"
        />
      </div>
    </FormProvider>
  );
}
